import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { KfHeroUtility } from '@klickinc/kf-react-components';
// Utility imports
import Image from '@components/utility/Image';
import { handleFBQ } from '@utils/FBQ';

import './styles.scss';

const DuchenneCommunities = ({ data }) => {

	useEffect(() => {
		const peerLinks = document.querySelectorAll('.peerNavigator');
		peerLinks.forEach((el) => {
			el.addEventListener('click', () => handleFBQ('Donate'))
		})

		return () => {
			peerLinks.forEach((el) => {
				el.removeEventListener('click', () => handleFBQ('Donate'))
			})
		}
	}, [])

    return (<>
        <section className='relative duchene-communities'>
            <div className="container container--inner">
                <div className='max-w-3xl'>
                    <KfHeroUtility addedClass='heading heading-hero-blue pb-4 xl:pt-14'>
                        Duchenne<br className='block sm:hidden' /> Communities
                    </KfHeroUtility>
                    <div className='max-w-xl'>
                        <p>There are many organizations created to help families living with Duchenne.</p>
                    </div>
                    <div className='blue-header-bg relative mt-8'>
						<h2 className='relative z-10 text-center md:text-left'>Connect with a <br className='block sm:hidden' />Peer Navigator</h2>
                        <Image
                            imageData={data.headerBlue}
                            alt=''
                            loading='eager'
                        />
                        <Image
                            imageData={data.headerBlueM}
                            alt=''
                            loading='eager'
                        />
                    </div>
                    <div className='duchene-communities--program'>
                        <h2 className='orange-heading italic uppercase mb-6 mt-10'>
                            The peer navigator program
                        </h2>
                        <h3 className='blue-heading uppercase mb-2'>Talk to other parents about Emflaza and DMD</h3>
                        <p className='mb-6'>The Peer Navigator Program can put you in contact with other families who understand your journey. Learning how others have managed challenges and achieved successes can help you gain insight into decisions you may need to make for your family. You can choose to speak with English or Spanish&#x2011;speaking navigators, according to your preferences.</p>
                        <div className='program--contact flex flex-col md:flex-row items-center justify-between md:justify-evenly lg:justify-between py-5'>
                            <a href='mailto:peernavigator@ptcbio.com' className='contact--email link-btn link-btn--blue' onClick={() => handleFBQ('Donate')}>Email</a>
                            <p className='my-6'>or</p>
                            <div className='contact--phone flex flex-row items-center'>
                                <Image
                                    imageData={data.duchennePhoneIcon}
                                    alt=''
                                    loading='eager'
                                />
                                <p>Call <a className="kf-anchorlink underline hover:no-underline focus:no-underline" href="tel:18662825873" target="_blank" rel="noreferrer noopener">1-866-282-5873</a></p>
                            </div>
                        </div>
                    </div>

                    <div className='duchene-communities--communities-list'>
                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>The Akari Foundation</h3>
                                <p>A group dedicated to educating, empowering, and advocating for the Hispanic immigrant community. They help with connecting families and providing resources in Spanish about rare diseases, specializing in DMD.</p>
                            </div>
							<a href="https://theakarifoundation.org/home/" target='_blank' rel="noreferrer noopener" className='peerNavigator link-btn link-btn--blue'>Visit</a>
                        </div>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>Cureduchenne</h3>
                                <p>This nonprofit organization supports Duchenne-related research and features events, workshops, and webinars on their site, which is available in Spanish. Visit <a href="https://www.cureduchenne.org/" target='_blank' rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>www.cureduchenne.org</a> to learn more.</p>
                            </div>
							<a href="https://www.cureduchenne.org/" target='_blank' rel="noreferrer noopener" className='peerNavigator link-btn link-btn--blue'>Visit</a>
                        </div>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>Muscular Dystrophy Association (MDA)</h3>
                                <p>There are more than 150 MDA care clinics in the United States and Puerto Rico with each location home to a full team of healthcare professionals. To find a specialized MDA clinic near you, visit <a href="https://www.mda.org/care/mda-care-centers" target='_blank' rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>www.mda.org/care/mda-care-centers</a> or call <a className="kf-anchorlink underline hover:no-underline focus:no-underline" href="tel:1-800-572-1717" target="_blank" rel="noreferrer noopener">1-800-572-1717</a>.</p>
                            </div>
							<a href="https://www.mda.org/" target='_blank' rel="noreferrer noopener" className='peerNavigator link-btn link-btn--blue'>Visit</a>
                        </div>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>Parent Project Muscular Dystrophy (PPMD) Certified Care Centers</h3>
                                <p>PPMD centers provide comprehensive care and services specifically for people living with Duchenne muscular dystrophy. These centers strive to provide accessible and comprehensive care to as many patients and families as possible. Centers certified by PPMD can be found at <a href='https://www.parentprojectmd.org/carecenters' target="_blank" className='underline hover:no-underline focus:no-underline' rel="noreferrer noopener">www.parentprojectmd.org/carecenters</a>. Support and care materials in Spanish can also be found at <a href='https://www.parentprojectmd.org/care/care-and-support-materials/care-and-support-materials-spanish/' target="_blank" className='underline hover:no-underline focus:no-underline' rel="noreferrer noopener">www.parentprojectmd.org/care/care-and-support-materials/care-and-support-materials-spanish/</a>.</p>
                            </div>
							<a href="https://www.parentprojectmd.org/care/find-a-certified-duchenne-care-center/" target='_blank' rel="noreferrer noopener" className='peerNavigator link-btn link-btn--blue'>Visit</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default DuchenneCommunities;


DuchenneCommunities.propTypes = {
    data: PropTypes.object.isRequired,
}
