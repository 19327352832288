import React from 'react';
import PropTypes from "prop-types"
import Image from '@components/utility/Image';
import './styles.scss';

const ResourcesCard = ({link, linkCallback, image, imageAlt, title, children}) => {
	return (
		<div className='resources--column'>
			<a href={link} target='_blank' rel='noopener noreferrer' onClick={linkCallback}>
				<span aria-hidden='true'>
					<Image
						imageData={image}
						alt={imageAlt}
						loading='eager'
					/>
				</span>
				<span className='sr-only'>{title}</span>
			</a>
			<div className='resource-description'>
				<h2 className='orange-heading uppercase'>
					<a href={link} target='_blank' rel='noopener noreferrer' onClick={linkCallback}>
						{title}
					</a>
				</h2>
				{children}
			</div>
		</div>
	);
};

export default ResourcesCard;


ResourcesCard.defaultProps = {
	linkCallback: () => {},
	imageAlt: "",
}

ResourcesCard.propTypes = {
	link: PropTypes.string.isRequired,
	linkCallback: PropTypes.func,
	image: PropTypes.object.isRequired,
	imageAlt: PropTypes.string,
	title: PropTypes.node.isRequired,
	children:  PropTypes.node.isRequired,
}
