import React, { useContext, useEffect } from 'react';
import { KfHeroUtility } from '@klickinc/kf-react-components';
import AppContext from "@src/context"
// Utility imports
import Image from '@components/utility/Image';
import Seo from '@components/utility/SEO';
import { graphql } from 'gatsby';
import { withArtDirection, getImage } from 'gatsby-plugin-image';

import { handleFBQ } from '@utils/FBQ';
import './styles.scss';

// Content imports
import ResourcesCard from '@components/content/resources-card/ResourcesCard';
import BottomCta from '@components/content/BottomCta';
import DuchenneCommunities from '@components/page-sections/duchenne-communities/DuchenneCommunities';

const CaregiverResources = ({data}) => {
	const ctx = useContext(AppContext);


	const image_resource1 = getImage(data.resource1);
	const image_resource_activity = getImage(data.resource_activity);
	const image_dosing = getImage(data.dosing);
	const image_daw = getImage(data.daw);

	const image_resource2 = withArtDirection(getImage(data.resource2), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.resource2M),
		},
	]);
	const image_resource3 = withArtDirection(getImage(data.resource3), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.resource3M),
		},
	]);
	const image_resource4 = withArtDirection(getImage(data.resource4), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.resource4M),
		},
	]);
	const image_resource5 = withArtDirection(getImage(data.resource5), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.resource5M),
		},
	]);
	const image_fb = withArtDirection(getImage(data.fb), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.fbM),
		},
	]);
	const image_headerBlue = getImage(data.headerBlue);
	const image_headerBlueM = getImage(data.headerBlueM);
	const image_duchennePhoneIcon = getImage(data.duchennePhoneIcon);

	useEffect(() => {
		ctx.setPageClass('page--family-resources');
	}, []);

	return (<>
		<section className='relative'>
			<div className="container container--inner container--padded">
				<KfHeroUtility addedClass='heading heading-hero-blue xl:pt-14'>
					Helpful<br className='sm:hidden' /> Resources For <br className='sm:hidden' />You And<br className='sm:hidden' /> Your Family
				</KfHeroUtility>
				<div className='max-w-3xl'>
					<p>Caring for a child affected by DMD can be overwhelming. Learning as much as you can about the disease and educating yourself about the support materials available to you can help you feel more grounded and in control. By providing you with these materials, we can help you better understand how EMFLAZA can help treat DMD.</p>
				</div>
			</div>
			<div className="container container--inner">
				<div className='grid grid-cols-1 sm:gap-y-16 lg:grid-cols-2 gap-y-8 my-16'>
					<ResourcesCard
						link="/caregiver-brochure.pdf"
						linkCallback={() => handleFBQ('AddToCart')}
						image={image_resource1}
						imageAlt=""
						title={<span className='mt-4 block lg:mt-0'>Family information guide</span>}
					>
						<p>Get clear and concise information about the benefits of EMFLAZA and information about PTC <span className='italic'>Cares</span>™. Use this guide to help with talking to your son's healthcare provider during your next appointment.</p>
						<a href="/caregiver-brochure.pdf" target='_blank' rel='noopener noreferrer' className='link-btn link-btn--blue' onClick={()=> handleFBQ('AddToCart')}>Download</a>
					</ResourcesCard>
					<ResourcesCard
						link="/activity-adventure-guide.pdf"
						image={image_resource_activity}
						imageAlt=""
						title="Activity adventure guide"
					>
						<p>Find great ideas for playtime using this handbook. It's full of exciting activities for having fun around the house. All games range from low to high intensity and can be adjusted to meet your son's comfort level.</p>
						<a href="/activity-adventure-guide.pdf" target='_blank' rel='noopener noreferrer' className='link-btn link-btn--blue'>Download</a>
					</ResourcesCard>
					<ResourcesCard
						link="/dosing-guide.pdf"
						image={image_dosing}
						imageAlt=""
						title="Weight-based Dosing guide"
					>
						<p>Annual dose adjustments associated with yearly weight changes may not be enough. Use this guide and regularly monitor your son's weight. Speak to your healthcare provider if you have questions.</p>
						<a href="/dosing-guide.pdf" target='_blank' rel='noopener noreferrer' className='link-btn link-btn--blue'>Download in English</a>
						<a href="/guia-de-administracion.pdf" target='_blank' rel='noopener noreferrer' className='link-btn link-btn--blue'>Download in Spanish</a>
					</ResourcesCard>
					<ResourcesCard
						link="/understanding-dispense-as-written.pdf"
						image={image_daw}
						imageAlt=""
						title="EMFLAZA VS GENERIC DEFLAZACORT FAQ"
					>
						<p>Understand the frequently asked questions regarding EMFLAZA, generic deflazacort, and the Dispense as Written (DAW) process.</p>
						<a href="/understanding-dispense-as-written.pdf" target='_blank' rel='noopener noreferrer' className='link-btn link-btn--blue'>Download</a>
					</ResourcesCard>
				</div>
			</div>
			<div className='resources-cta spanish'>
				<div className='resources-cta--inner container'>
					<div lang='es' className='resources-cta--copy'>
						<h2 className='orange-heading uppercase'>Obtenga más información sobre emflaza en Español</h2>
						<p className='mt-3.5 mb-5'>Vea los datos, los recursos y otra información importante de una manera más fácil de comprender.</p>
						<a className="kf-anchorlink link-btn link-btn--blue" href="/es">Información en Español</a>
					</div>
				</div>
			</div>
			<div className="container container--inner">
				<div className='grid grid-cols-1 sm:gap-y-16 lg:grid-cols-2 gap-y-8 my-16'>
					<ResourcesCard
						link="/PTC-cares-brochure.pdf"
						linkCallback={() => handleFBQ('AddtoWishlist')}
						image={image_resource2}
						title={<>PTC <span className='italic'>Cares</span>™ brochure</>}
					>
						<p>Everything you need to know about the prescription process and patient assistance programs for EMFLAZA.</p>
						<a href='/PTC-cares-brochure.pdf' target='_blank' rel='noreferrer' className='link-btn link-btn--blue' onClick={() => handleFBQ('AddtoWishlist')}>Download</a>
					</ResourcesCard>

					<ResourcesCard
						link="/prescription-start-form.pdf"
						linkCallback={() => handleFBQ('InitiateCheckout')}
						image={image_resource3}
						title="Prescription Start Form"
					>
						<p>Get your child started on EMFLAZA by completing the form with the help of your child‘s healthcare provider.</p>
						<a href='/prescription-start-form.pdf' target='_blank' rel='noreferrer' className='link-btn link-btn--blue' onClick={() => handleFBQ('InitiateCheckout')}>Download</a>
					</ResourcesCard>

					<ResourcesCard
						link="/PTC-cares-clinic-visit-readiness-sheet.pdf"
						linkCallback={() => handleFBQ('Purchase')}
						image={image_resource4}
						title="Clinic visit readiness sheet"
					>
						<p>Gather and record important information or questions for your child's healthcare provider so you can make the most of your next visit.</p>
						<a href='/PTC-cares-clinic-visit-readiness-sheet.pdf' target='_blank' rel='noreferrer' className='link-btn link-btn--blue' onClick={() => handleFBQ('Purchase')}>Download</a>
					</ResourcesCard>

					<ResourcesCard
						link="/navigating-scientific-publications.pdf"
						linkCallback={() => handleFBQ('FindLocation')}
						image={image_resource5}
						title="Navigating scientific publications tool"
					>
						<p>A resource to familiarize yourself with the elements and terms that are commonly seen in different types of publications.</p>
						<a href='/navigating-scientific-publications.pdf' target='_blank' rel='noreferrer' className='link-btn link-btn--blue' onClick={() => handleFBQ('FindLocation')}>Download</a>
					</ResourcesCard>
				</div>
			</div>
			<div className='resources-cta facebook'>
				<div className='resources-cta--inner container'>
					<div className='resources-cta--copy'>
						<div className='flex flex-col sm:flex-row items-center justify-between'>
							<div className='fb-link-info'>
								<h2 className='orange-heading uppercase'>
									<a href='https://www.facebook.com/hisEMFLAZA' target='_blank' rel="noreferrer">Find us on Facebook</a>
								</h2>
								<p className='mt-3.5 mb-5'>Get to know other families managing DMD and learn more about EMFLAZA on our Facebook page.</p>
								<a href='https://www.facebook.com/hisEMFLAZA' target='_blank' rel="noreferrer" className='link-btn link-btn--blue'>Visit</a>
							</div>
							<a href='https://www.facebook.com/hisEMFLAZA' target='_blank' rel="noreferrer">
								<span aria-hidden='true'>
									<Image
										imageData={image_fb}
										alt=''
										loading='eager'
									/>
								</span>
								<span className='sr-only'>Find us on Facebook</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<DuchenneCommunities data={{headerBlue: image_headerBlue, headerBlueM: image_headerBlueM, duchennePhoneIcon: image_duchennePhoneIcon}} />
		<section className='relative pt-14 lg:pt-20'>
			<div className="container container--inner pb-20">
				<p className='mb-20 max-w-3xl'>PTC does not warrant, whether expressed or implied, that the list of centers is accurate or complete. PTC does not recommend or endorse any center, hospital, or healthcare professional included above for any purpose, nor is the list to verify a center's, hospital's, or specific healthcare professional's credentials or qualifications. It is your responsibility to select a center, hospital, and/or healthcare professional appropriate for your needs, and you assume full responsibility for your interactions with any center, hospital, or healthcare professional you contact through the center list. PTC is not responsible for the medical care or advice given by any center, hospital, or healthcare professional you choose to contact.</p>
				<BottomCta
					ctaUrl="/understanding-corticosteroids#understanding-switch "
					image={data}
					hasAnchor
				>
					Considering a switch
				</BottomCta>
			</div>
		</section>
	</>)
}

export default CaregiverResources;


export const pageQuery = graphql`
  query {
	resource1: file(relativePath: {eq: "pages/resources/desktop/resource-1.png"}) {
		childImageSharp {
			gatsbyImageData
		}
	},
	resource_activity: file(relativePath: {eq: "pages/resources/desktop/resource-activity-guide.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	dosing: file(relativePath: {eq: "pages/dosing/dosing-guide.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	daw: file(relativePath: {eq: "pages/resources/desktop/daw-faq.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource2: file(relativePath: {eq: "pages/resources/desktop/resource-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource2M: file(relativePath: {eq: "pages/resources/mobile/resource-2-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource3: file(relativePath: {eq: "pages/resources/desktop/resource-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource3M: file(relativePath: {eq: "pages/resources/mobile/resource-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource4: file(relativePath: {eq: "pages/resources/desktop/resource-4.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource4M: file(relativePath: {eq: "pages/resources/mobile/resource-4-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource5: file(relativePath: {eq: "pages/resources/desktop/resource-5.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	resource5M: file(relativePath: {eq: "pages/resources/mobile/resource-5-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	fb: file(relativePath: {eq: "pages/resources/desktop/fb.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	fbM: file(relativePath: {eq: "pages/resources/mobile/fb-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	headerBlue: file(relativePath: {eq: "pages/resources/header-blue-bg.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	headerBlueM: file(relativePath: {eq: "pages/resources/header-blue-bg-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	duchennePhoneIcon: file(relativePath: {eq: "icons/duchenne-phone-icon.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
    return (
		<Seo
			title='Resources for Caregivers - EMFLAZA® (deflazacort)'
			description='Get materials to help educate yourself about DMD and get a better understanding of how EMFLAZA® (deflazacort) can help. See full Prescribing Information & Important Safety Information.'
		/>
    );
};
